
class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     * Set topbar's height to menu's top to make space for topbar.
     */
    static setHeight() {
        const body = document.body;
        const topbar = document.getElementById('topbar');
        const menu = document.getElementById('menu-container');

        body.style.paddingTop = `${menu.offsetHeight + topbar.offsetHeight}px`;
        menu.style.top = `${topbar.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let checkbox = document.getElementById('nav-toggle');
            let menu = document.getElementById('menu-container');
            let topbar = document.getElementById('topbar');
            let currentScrollPos = window.pageYOffset;
            let navList = document.getElementsByClassName('nav__list')[0];

            // if menu's height is bigger than scrolled area start scrolling
            if (menu.offsetHeight < currentScrollPos) {
                menu.classList.add('menu-container--is-scrolled');

                // if menu is far away from page start allow animation
                if (currentScrollPos > (menu.offsetHeight * 2)) {
                    menu.classList.add('menu-container--is-animated');
                } else {
                    menu.classList.remove('menu-container--is-animated');
                }

                menu.style.top = '0';
            } else {
                menu.classList.remove('menu-container--is-scrolled');
                menu.style.top = `${topbar.offsetHeight}px`;
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav__list')[0];

        const links = document.querySelectorAll('.nav a');

        checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                bodyScrollLock.disableBodyScroll(navList);
            } else {
                bodyScrollLock.enableBodyScroll(navList);
            }
        });

        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', function (){
                if ( window.matchMedia('(max-width: 1199px)').matches ) checkbox.click();
            });
        }
    }
}
