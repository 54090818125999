
class Logos {

    static init() {
        const logosContainer = document.getElementById('js-logos');

        if (logosContainer) {
            let slider = tns({
                mode: 'gallery',
                animateDelay: 300,
                container: logosContainer,
                items: 1,
                slideBy: 'page',
                autoplay: true,
                autoplayButtonOutput: false,
                controls: false,
                nav: false,
                responsive: {
                    567: {
                        items: 3
                    },
                    1024: {
                        items: 4
                    }
                }
            });
        }
    }
}
