
document.addEventListener('DOMContentLoaded', (event) => {
    Menu.setHeight();

    // set again
    setTimeout(function () {
        Menu.setHeight();
    }, 500);
    Menu.setScrolling();
    Menu.listenOpen();

    // smoothscroll plugin has to be install as npm package
    let scroll = new SmoothScroll('a[data-scroll]', {
        header: '[data-scroll-menu]'
    });

    Video.playBtns();
    Video.stopBtn();
    Video.listenVideo();

    //It also supports NodeList
    if ( window.matchMedia('(min-width:1280px)').matches ) {
        VanillaTilt.init(document.querySelectorAll(".js-tilt-effect"), {
            max: 10,
            speed: 4000
        });
    }

    /*
    let doneOnce = false;
    window.addEventListener('scroll', function () {
        let isOnViewPort = isScrolledIntoView.init( document.getElementsByClassName('js-number-count')[0] );

        if (isOnViewPort && !doneOnce) {
            Counter.init(document.getElementsByClassName('js-number-count'));
            doneOnce = true;
        }
    });
     */
    Logos.init();

    if ( window.matchMedia('(min-width:768px)').matches ) {
        PriceList.setText();
        PriceList.setBoxes();
    }

    PhotoSwipePlugin.init();

    var imageHeader = document.getElementsByClassName('header__bg');
    new simpleParallax(imageHeader, {
        overflow: true
    });
    var image = document.getElementsByClassName('section--bg__img');
    new simpleParallax(image, {
        overflow: true
    });

    objectFitImages();
});

