/**
 * Return 1 if number is odd and 0 when number is even.
 * @param num
 * @returns {number}
 */
function isOdd(num) {
    return num % 2;
}

/**
 * FadeIn animation. No special styles required.
 * @param el: animated element
 * @param time: animation duration
 */
function fadeIn(el, time) {
    el.style.opacity = 0;

    let last = +new Date();
    const tick = function () {
        el.style.opacity = +el.style.opacity + (new Date() - last) / time;
        last = +new Date();

        if (+el.style.opacity < 1) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }
    };

    tick();
}