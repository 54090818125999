

function setHeights(nodeList) {
    let highest = 0;

    for (let i = 0; i < nodeList.length; i++) {
        highest < nodeList[i].scrollHeight ? highest = nodeList[i].scrollHeight : highest = highest;
    }
    for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.height = `${highest}px`;
    }
}

class PriceList {

    static setBoxes() {
        const boxes = document.getElementsByClassName(' js-price-list-box');

        if (boxes) setHeights(boxes);
    }

    static setText() {
        const texts = document.getElementsByClassName('js-price-list-text');

        if (texts) setHeights(texts);
    }
}
