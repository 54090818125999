
class Video {

    static play() {
        const video = document.getElementById('kardiotenisVideo');
        const stop = document.querySelector('.js-video-stop');

        if (video) {
            video.style.display = 'block';
            video.play();
        }

        if (stop) stop.style.display = 'block';
    }

    static stopBtn() {
        const stop = document.querySelector('.js-video-stop');
        const video = document.getElementById('kardiotenisVideo');

        if (stop && video) {
            stop.addEventListener('click', function (e) {
                e.preventDefault();
               video.pause();
               video.currentTime = 0;
               stop.style.display = 'none';
               video.style.display = 'none';
               Video.showPre();
            });
        }
    }

    static hidePre() {
        const container = document.getElementById('kardiotenisVideoPre');
        const img = document.getElementById('kardiotenisImg');

        if (container && img) {
            container.style.display = 'none';
            img.style.display = 'none';
        }
    }

    static showPre() {
        const container = document.getElementById('kardiotenisVideoPre');
        const img = document.getElementById('kardiotenisImg');

        if (container && img) {
            container.style.display = 'block';
            img.style.display = 'block';
        }
    }

    static playBtns() {
        const btns = document.getElementsByClassName('js-video-play');

        if (btns) {
            for (let i = 0; i < btns.length; i++) {
                btns[i].addEventListener('click', function (e) {
                    e.preventDefault();
                    Video.play();
                    Video.hidePre();
                });
            }
        }
    }

    static listenVideo() {
        const video = document.getElementById('kardiotenisVideo');
        const stop = document.querySelector('.js-video-stop');

        if (video && stop) {
            video.addEventListener('ended', function () {
                stop.style.display = 'none';
                video.style.display = 'none';
                Video.showPre();
            });
        }

        if (video) {
            video.addEventListener('pause', function () {
                stop.style.display = 'none';
                video.style.display = 'none';
                Video.showPre();
            });
        }
    }
}
